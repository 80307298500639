@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .app {
    @apply flex items-center flex-col p-4 w-full max-w-7xl mx-auto;
  }

  .app-header {
    @apply text-center py-8 bg-gradient-to-r from-blue-400 to-purple-500 text-white mb-8;
  }

  .app-header h1 {
    @apply text-4xl mb-4 font-bold;
  }

  .header-description {
    @apply max-w-2xl mx-auto;
  }

  .primary-text {
    @apply text-xl mb-2 font-medium;
  }

  .secondary-text {
    @apply text-base text-white/90;
  }

  .dropzone {
    @apply border-2 border-dashed border-primary p-10 mb-5 text-center rounded-lg cursor-pointer text-gray-400 transition-all duration-200;
  }

  .dropzone:hover {
    @apply border-primary-hover bg-primary/5 -translate-y-0.5;
  }

  .dropzone p {
    @apply m-0 text-lg;
  }

  .file-list {
    @apply flex flex-col gap-4;
  }

  .file-card {
    @apply flex items-start bg-card border border-border p-4 rounded-lg gap-6;
  }

  .file-preview {
    @apply w-24 h-24 rounded object-cover;
  }

  .file-details {
    @apply flex-1 text-text-primary;
  }

  .file-name {
    @apply text-base m-0 text-text-primary;
  }

  .file-status {
    @apply text-sm text-text-secondary;
  }

  .button {
    @apply px-4 py-2 rounded-lg font-semibold text-sm cursor-pointer transition-all duration-200 inline-flex items-center justify-center gap-2 min-w-[120px] uppercase tracking-wider;
  }

  .button-primary {
    @apply bg-gradient-to-r from-primary to-primary-hover text-white shadow-lg shadow-primary/30;
  }

  .button-primary:hover {
    @apply -translate-y-0.5 shadow-xl;
  }

  .button-primary:active {
    @apply translate-y-0;
  }

  .button-secondary {
    @apply bg-card text-text-primary border border-border;
  }

  .button-secondary:hover {
    @apply bg-gray-800 -translate-y-0.5;
  }

  .download-button {
    @apply bg-transparent text-primary border border-primary px-4 py-2 rounded font-semibold text-sm cursor-pointer transition-all duration-200 uppercase tracking-wider;
  }

  .download-button:hover {
    @apply bg-primary text-white -translate-y-0.5 shadow-sm;
  }

  .all-download {
    @apply my-6 mx-auto block px-8 py-4 bg-gradient-to-r from-primary to-primary-hover text-white rounded-lg font-semibold text-base cursor-pointer transition-all duration-200 shadow-md uppercase tracking-wider;
  }

  .all-download:hover {
    @apply -translate-y-0.5 shadow-xl;
  }

  .all-download:active {
    @apply translate-y-0;
  }
}
